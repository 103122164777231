import React, {FC, useState} from 'react';
import {OrdersEntity, SlotDevExpenses} from '../../../api-types';
import {Button, Divider, makeStyles,} from '@material-ui/core';
import ReactPivot from 'react-pivot-webpack';
import {formatAmount, formatHours} from "~/config/pages/moneyFlow/formatters";

const useStyles = makeStyles({
  divider: {
    margin: '12px 0'
  },
  table: {
    minWidth: 1200,
  },
  tableRow: {
    background: 'rgb(187, 187, 187)',
    '& .MuiTableCell-root': {
      padding: '16px 8px',
      color: '#000000',
    },
  },
});

type Props = {
  orders: OrdersEntity[];
  slotDevExpenses: SlotDevExpenses[];
};

const Profitability: FC<Props> = ({orders, slotDevExpenses}) => {
  const [isOnlyEndedOrders, setIsOnlyEndedOrders] = useState(true);
  const classes = useStyles();

  const filteredOrders = orders.filter(order =>
    !isOnlyEndedOrders || order.factDate != null
  )
  const slotsSoldHours = slotDevExpenses
    .map(slotInfo => {
        const soldHoursSum = filteredOrders
          .filter(it => it.slotId == slotInfo.slotId)
          .map(it => it.soldHours)
          .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        return {
          slotId: slotInfo.slotId,
          soldHours: soldHoursSum,
          devExpPerHour: slotInfo.amount / soldHoursSum
        }
      }
    );

  const data = filteredOrders.map(order => {
    const fullCostRub = order.factCostPriceRub + order.calculatedCostPriceRub;
    const profitRub = order.revenueRub - fullCostRub
    const devExpRub = order.soldHours * (slotsSoldHours.find(it => it.slotId == order.slotId)?.devExpPerHour ?? 0)
    const grossProfitRub = profitRub - devExpRub
    return {
      // dimensions
      total: '',
      orderNumber: order.orderNumber,
      orderJiraCategory: order.orderJiraCategory,
      responsibleUser: order.responsibleUser,
      slotName: order.slotName,
      status: order.productionStatus,
      // metrics
      soldHours: order.soldHours,
      trackingHours: order.trackingHours,
      rateRub: order.rateRub,
      fullCostRub: fullCostRub,
      revenueRub: order.revenueRub,
      profitRub: profitRub,
      marginPct: order.revenueRub != 0 ? profitRub / order.revenueRub : 0,
      devExpensesRub: devExpRub,
      grossProfitRub: grossProfitRub,
      grossMarginPct: order.revenueRub != 0 ? grossProfitRub / order.revenueRub : 0
    }
  });
  const dim = [
    {value: 'total', title: 'Total'},
    {value: 'responsibleUser', title: 'Менеджер'},
    {value: 'slotName', title: 'Слот'},
    {value: 'orderJiraCategory', title: 'Проект'},
    {value: 'orderNumber', title: 'Заказ'},
    {value: 'status', title: 'Статус'},
  ]
  const reduce = function (row, memo) {
    const oldSoldHoursTotal = (memo.soldHoursTotal || 0)
    const oldRateRubAvg = (memo.rateRubAvg || 0)

    memo.soldHoursTotal = oldSoldHoursTotal + row.soldHours
    memo.trackingHoursTotal = (memo.trackingHoursTotal || 0) + row.trackingHours
    // среднее арифмитическое
    memo.rateRubAvg = ((oldSoldHoursTotal * oldRateRubAvg) + (row.trackingHours * row.rateRub)) /
      (oldSoldHoursTotal + row.trackingHours)
    memo.fullCostRubTotal = (memo.fullCostRubTotal || 0) + row.fullCostRub
    memo.revenueRubTotal = (memo.revenueRubTotal || 0) + row.revenueRub
    memo.profitRubTotal = (memo.profitRubTotal || 0) + row.profitRub
    memo.marginPct = memo.revenueRubTotal != 0 ? memo.profitRubTotal / memo.revenueRubTotal : 0
    memo.devExpensesRubTotal = (memo.devExpensesRubTotal || 0) + row.devExpensesRub
    memo.grossProfitRubTotal = (memo.grossProfitRubTotal || 0) + row.grossProfitRub
    memo.grossMarginPct = memo.revenueRubTotal != 0 ? memo.grossProfitRubTotal / memo.revenueRubTotal : 0
    return memo
  }
  const isDevExpensesAvailable: boolean = data
    .reduce((cur, next) => cur + next.devExpensesRub, 0) != 0
  const calculations = [
    {
      title: 'Потрачено часов', value: 'trackingHoursTotal',
      template: function (val, row) {
        return formatHours(val)
      },
      sortBy: function (row) {
        return isNaN(row.trackingHoursTotal) ? 0 : row.trackingHoursTotal
      }
    },
    {
      title: 'Продано часов', value: 'soldHoursTotal',
      template: function (val, row) {
        return formatHours(val)
      },
      sortBy: function (row) {
        return isNaN(row.soldHoursTotal) ? 0 : row.soldHoursTotal
      }
    },
    {
      title: 'Рейт', value: 'rateRubAvg',
      template: function (val, row) {
        return formatAmount(val) + " ₽"
      },
      sortBy: function (row) {
        return isNaN(row.rateRubAvg) ? 0 : row.rateRubAvg
      }
    },
    {
      title: 'Себестоимость', value: 'fullCostRubTotal',
      template: function (val, row) {
        return formatAmount(val) + " ₽"
      },
      sortBy: function (row) {
        return isNaN(row.fullCostRubTotal) ? 0 : row.fullCostRubTotal
      }
    },
    {
      title: 'Выручка', value: 'revenueRubTotal',
      template: function (val, row) {
        return formatAmount(val) + " ₽"
      },
      sortBy: function (row) {
        return isNaN(row.revenueRubTotal) ? 0 : row.revenueRubTotal
      }
    },
    {
      title: 'Прибыль', value: 'profitRubTotal',
      template: function (val, row) {
        return formatAmount(val) + " ₽"
      },
      sortBy: function (row) {
        return isNaN(row.profitRubTotal) ? 0 : row.profitRubTotal
      }
    },
    {
      title: 'Рентабельность', value: 'marginPct',
      template: function (val, row) {
        return (val * 100).toFixed(0) + " %"
      },
      sortBy: function (row) {
        return isNaN(row.marginPct) ? 0 : row.marginPct
      }
    }
  ].concat(
    isDevExpensesAvailable ?
      [
        {
          title: 'Development Expenses', value: 'devExpensesRubTotal',
          template: function (val, row) {
            return formatAmount(val) + " ₽"
          },
          sortBy: function (row) {
            return isNaN(row.devExpensesRubTotal) ? 0 : row.devExpensesRubTotal
          }
        },
        {
          title: 'Валовая производственная маржа', value: 'grossProfitRubTotal',
          template: function (val, row) {
            return formatAmount(val) + " ₽"
          },
          sortBy: function (row) {
            return isNaN(row.grossProfitRubTotal) ? 0 : row.grossProfitRubTotal
          }
        },
        {
          title: 'Рентабельность ВПМ', value: 'grossMarginPct',
          template: function (val, row) {
            return (val * 100).toFixed(0) + " %"
          },
          sortBy: function (row) {
            return isNaN(row.grossMarginPct) ? 0 : row.grossMarginPct
          }
        },
      ] :
      []
  )
  const defaultDimensions = [
    'Total',
    'Проект',
    'Слот'
  ];

  return (
    <>
      <Button variant="contained" color="default" onClick={() => setIsOnlyEndedOrders((prev) => !prev)}>
        {isOnlyEndedOrders ? 'Показать незавершенные заказы' : 'Скрыть незавершенные заказы'}
      </Button>
      <Divider className={classes.divider}/>

      <ReactPivot
        rows={data}
        dimensions={dim}
        reduce={reduce}
        calculations={calculations}
        activeDimensions={defaultDimensions}
        nPaginateRows={1000}/>

    </>
  );
};

export default Profitability;
