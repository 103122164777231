import {
  GroupedHealthStatus,
  GroupedProject,
  GroupedSlot,
  OrdersEntity,
  SlotDevExpenses,
} from '~/config/pages/reports/api-types';

export const getHoursNumber = (input?: any): number => {
  if (input && typeof input == 'string') {
    input = parseFloat(input);
  }
  return (!!input && !!parseFloat(input) && parseFloat(input.toFixed(2))) || 0.0;
};

export const processOrdersDataByHealthGroup = (data: OrdersEntity[]) => {
  const typeSums = {};

  data.forEach((item) => {
    if (item.healthStatus) {
      if (!typeSums[item.healthStatus]) {
        typeSums[item.healthStatus] = {
          sumSoldHours: 0,
          sumTrackingHours: 0,
          averageRateRub: 0,
          sumFactCostPriceRub: 0,
          sumCalculatedCostPriceRub: 0,
          sumRevenueRub: 0,
        };
      }
      typeSums[item.healthStatus].averageRateRub = (
        (typeSums[item.healthStatus].sumSoldHours * typeSums[item.healthStatus].averageRateRub) +
        (item.soldHours * item.rateRub)
      ) / (typeSums[item.healthStatus].sumSoldHours + item.soldHours);

      typeSums[item.healthStatus].sumSoldHours += item.soldHours;
      typeSums[item.healthStatus].sumTrackingHours += item.trackingHours;
      typeSums[item.healthStatus].sumFactCostPriceRub += item.factCostPriceRub;
      typeSums[item.healthStatus].sumCalculatedCostPriceRub += item.calculatedCostPriceRub;
      typeSums[item.healthStatus].sumRevenueRub += item.revenueRub;
    }
  });

  const canBeCompletedList: GroupedHealthStatus[] = [];
  const canNotBeCompletedList: GroupedHealthStatus[] = [];
  Object.keys(typeSums).forEach((healthStatus) => {
    const newObj: GroupedHealthStatus = {
      healthStatus: healthStatus,
      sumSoldHours: typeSums[healthStatus].sumSoldHours,
      sumTrackingHours: typeSums[healthStatus].sumTrackingHours,
      averageRateRub: typeSums[healthStatus].averageRateRub,
      sumFactCostPriceRub: typeSums[healthStatus].sumFactCostPriceRub,
      sumCalculatedCostPriceRub: typeSums[healthStatus].sumCalculatedCostPriceRub,
      sumRevenueRub: typeSums[healthStatus].sumRevenueRub,
    };

    if (['DONE', 'REAL', 'OPT_GOOD'].includes(healthStatus)) {
      canBeCompletedList.push(newObj);
    } else if (['FAIL', 'OPT_BAD'].includes(healthStatus)) {
      canNotBeCompletedList.push(newObj);
    }
  });

  return { canBeCompletedList, canNotBeCompletedList };
};

export const safeDivide = (numerator: number, denominator: number) => {
  if (denominator === 0 || isNaN(denominator) || isNaN(numerator)) {
    return 0;
  }
  return numerator / denominator;
};

export const groupSlotOrdersData = (data: OrdersEntity[], slotDevExpenses: SlotDevExpenses[]) => {
  return data.reduce<GroupedSlot[]>((acc, curr) => {
    let slotItem = acc.find((item) => item.slotId === curr.slotId);

    const {
      slotId,
      slotName,
      revenueRub,
      soldHours,
      trackingHours,
      factCostPriceRub,
      calculatedCostPriceRub,
      responsibleUser,
      orderJiraCategory,
    } = curr;

    const priceRub = factCostPriceRub + calculatedCostPriceRub;
    const slotDevExpensesItem = slotDevExpenses.find(it => it.slotId === slotId);
    const developmentExpenses = slotDevExpensesItem?.amount ?? 0;

    if (!slotItem) {
      slotItem = {
        slotId: slotId ?? 0,
        slotName: slotName ?? 'Unlinked from slot',
        users: [],
        sumRevenueRub: revenueRub,
        sumSoldHours: soldHours,
        sumTrackingHours: trackingHours,
        sumPriceRub: priceRub,
        developmentExpenses: developmentExpenses,
      };
      acc.push(slotItem);
    } else {
      slotItem.sumRevenueRub = slotItem.sumRevenueRub + revenueRub;
      slotItem.sumSoldHours = slotItem.sumSoldHours + soldHours;
      slotItem.sumTrackingHours = slotItem.sumTrackingHours + trackingHours;
      slotItem.sumPriceRub = slotItem.sumPriceRub + priceRub;
    }

    let userItem = slotItem.users.find((u) => u.user === responsibleUser);

    if (!userItem) {
      userItem = {
        user: responsibleUser ?? 'Unlinked from user',
        projects: [],
        sumRevenueRub: revenueRub,
        sumSoldHours: soldHours,
        sumTrackingHours: trackingHours,
        sumPriceRub: priceRub,
      };
      slotItem.users.push(userItem);
    } else {
      userItem.sumRevenueRub = userItem.sumRevenueRub + revenueRub;
      userItem.sumSoldHours = userItem.sumSoldHours + soldHours;
      userItem.sumTrackingHours = userItem.sumTrackingHours + trackingHours;
      userItem.sumPriceRub = userItem.sumPriceRub + priceRub;
    }

    let projectItem = userItem.projects.find((p) => p.project === orderJiraCategory);

    if (!projectItem) {
      projectItem = {
        project: orderJiraCategory ?? 'NoName',
        revenueRub,
        orders: [curr],
        soldHours,
        trackingHours,
        priceRub,
      };
      userItem.projects.push(projectItem);
    } else {
      projectItem.revenueRub = projectItem.revenueRub + revenueRub;
      projectItem.orders.push(curr);
      projectItem.soldHours = projectItem.soldHours + soldHours;
      projectItem.trackingHours = projectItem.trackingHours + trackingHours;
      projectItem.priceRub = projectItem.priceRub + priceRub;
    }

    return acc;
  }, []);
};

export const groupProjectOrdersData = (data: OrdersEntity[], slotDevExpenses: SlotDevExpenses[]) => {
  return data.reduce<GroupedProject[]>((acc, curr) => {
    let projectItem = acc.find((p) => p.project === curr.orderJiraCategory);

    const {
      slotId,
      slotName,
      revenueRub,
      soldHours,
      trackingHours,
      factCostPriceRub,
      calculatedCostPriceRub,
      orderJiraCategory,
      // devExpensesRub,
    } = curr;

    const priceRub = factCostPriceRub + calculatedCostPriceRub;

    if (!projectItem) {
      projectItem = {
        project: orderJiraCategory ?? 'NoName',
        slots: [],
        sumRevenueRub: revenueRub,
        sumSoldHours: soldHours,
        sumTrackingHours: trackingHours,
        sumPriceRub: priceRub,
        developmentExpenses: 0
      };
      acc.push(projectItem);
    } else {
      projectItem.sumRevenueRub = projectItem.sumRevenueRub + revenueRub;
      projectItem.sumSoldHours = projectItem.sumSoldHours + soldHours;
      projectItem.sumTrackingHours = projectItem.sumTrackingHours + trackingHours;
      projectItem.sumPriceRub = projectItem.sumPriceRub + priceRub;
      // projectItem.developmentExpenses = projectItem.developmentExpenses + devExpensesRub;
    }

    let slotItem = projectItem.slots.find((s) => s.slotName === slotName);
    const slotDevExpensesItem = slotDevExpenses.find(it => it.slotId === slotId);
    const developmentExpenses = slotDevExpensesItem?.amount ?? 0;

    if (!slotItem) {
      slotItem = {
        slotId: slotId ?? 0,
        slotName: slotName ?? 'Unlinked from slot',
        orders: [],
        sumRevenueRub: revenueRub,
        sumSoldHours: soldHours,
        sumTrackingHours: trackingHours,
        sumPriceRub: priceRub,
        developmentExpenses: developmentExpenses,
      };
      projectItem.slots.push(slotItem);
    }

    slotItem.orders.push(curr);

    return acc;
  }, []);
};
